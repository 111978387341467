<template>
  <div class="information-menu">
    <div class="information-container">
      <img class="information-svg" src="../assets/SVG/INFORMATION.svg" alt="INFORMATION">
    </div>

    <div class="info-close-button-container">
      <img :src="require('../assets/SVG/X-BLACK.svg')" alt="Close" @click="infoclosePage">
    </div>

    <div class="information-content">
      <img class="information-img" src="../assets/TSG.jpg" alt="東京生息現代">
      <h1 class="title">映画『東京生息現代』<br>キャスト募集のお知らせ</h1>

      <div class="event-details">
        <p class="details-text">2025年制作予定の映画『東京生息現代』のキャストを一般募集いたします。演技経験は問いません。俳優、あるいは俳優志望でなくてもかまいません。ただし、本企画においては、キャストの方が実際に暮らしているご自宅が撮影地となります。ただ、そのひとつの条件を踏まえたうえで私とともに作品づくりをしてみたいという方は、ぜひ下記までご連絡を。あなたの勇断を期待します。未知の方々との出会いをなにより心待ちにしています。</p>
        <p class="details-text">genfilm1980@gmail.com</p>
        <p class="details-text">(2025年4月末日〆切)</p>
        <p class="details-text">2025年1月25日</p>
        <p class="details-text">堀江実</p>
      </div>

      <div class="project-info">
        <h3 class="sub-title">映画『東京生息現代』</h3>
        <div class="compact-details">
          <p class="details-text">形式：連作短編・フィクション</p>
          <p class="details-text">制作年：2025年</p>
          <p class="details-text">完成予定尺：100分</p>
          <p class="details-text">監督・脚本・撮影：堀江実</p>
        </div>
      </div>

      <div class="overview">
        <h3 class="sub-title">◯ 概要</h3>
        <p class="details-text">私、堀江実が2025年初めに立ち上げる新プロジェクトは、連作短編集『東京生息現代（とうきょうせいそくげんだい）』です。</p>
        <p class="details-text">出演者、ひとり。撮影者、ひとり。ふたりきり、出演者が実際に生活を営む自宅を舞台とした10分ほどの短編映画の制作をします。さらに、それらを10本ほど繋ぎあわせ、一本の長編映画の生成を試みます。部屋の様子はその人の精神の表れです。本作では人物と部屋は等価に描かれます。そうした人物たちの生息風景を、時に重ねて、時にずらしながら、モザイク状の群像劇として構成することによって、この霞のかかった理不尽な外界を一瞬でも、鋭く逆照射できないかと考えています。主題はコロナ禍を経た私たち自身の身体と生活、そして夢です。村上春樹の連作短編集『神の子どもたちはみな踊る』が「after the quake」であることに倣えば「after the pandemic」とも呼べる作品になることでしょう。</p>
      </div>

      <div class="director-profile">
        <h3 class="sub-title">◯ 監督プロフィール</h3>
        <p class="details-text">1980年東京生まれ。映画作家。劇映画から記録映画まで横断的な作品群を展開中。</p>
        <p class="details-text">『首くくり栲象の庭』『イバラ・夜の祈り』など。 <a href="http://minoruhorie.com/">http://minoruhorie.com/</a></p>
      </div>

      <div class="notes">
        <h3 class="sub-title">◯ いくつかの注釈</h3>
        <ul>
          <li>一人暮らし、単身者でなくてもかまいません。東京ドイツ村がそうであるように、実際に東京都在住でなくてもかまいません。</li>
          <li>個々の撮影期間は、打ち合わせ、ロケハンなどを除いて1〜3日間程度と想定していますが、取り組む作品性によって別の対応が必要となることもあります。</li>
          <li>撮影中は私、堀江実と二人きりになる可能性がございます。立会者をご希望の方は遠慮なくお申し付けください。</li>
          <li>ご自宅の外観は撮影しません。また場所バレとなるような撮影も一切しません。</li>
          <li>薄謝で恐縮ですが、謝礼はお支払いします。また、制作に必要な経費はすべてこちらで負担します。</li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    infoclosePage() {
      window.history.back(); 
    }
  }
}
</script>

<style scoped>
.information-menu {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f4f4f4;
}

.information-container {
  width: 80%;
  max-width: 640px;
  margin-bottom: 20px;
  text-align: center;
}

.information-content {
  width: 90%;
  max-width: 880px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: justify;
}

.title {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  color: black; 
  text-align: left; 
  margin-bottom: 1rem;
  margin-top: 0.25rem;
  font-size: 2rem;
}

.sub-title {
  font-size: 1.2rem;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #555;
  border-bottom: 0px solid #ddd;
  padding-bottom: 5px;
}

.compact-details p {
  font-family: "Zen Kaku Gothic New", sans-serif;
  margin-bottom: 0.2rem;
  line-height: 0.5rem;
}

.event-details, .project-info, .overview, .director-profile, .notes {
 
  margin-bottom: 20px;
}

.details-text {
  margin-bottom: 0.5rem;
  font-family: "Shippori Mincho", serif;
  font-size: 1rem;
  color: #444;
  line-height: 1.75em;
}

ul {
  padding-left: 20px;
  font-family: "Shippori Mincho", serif;
  font-size: 1rem;
}

li {
  margin-bottom: 10px;
  line-height: 1.6;
}

a {
  color: #0066cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.information-svg {
  margin-top: 10vh;
}

.information-img {
  width: 70%;
}


.info-close-button-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.info-close-button-container img {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .information-content {
    padding: 15px;
    border-radius: 8px;
  }

  .title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .sub-title {
    font-size: 1rem;
    font-weight: bold;
  }

  .details-text {
    font-size: 0.9rem;
  }

  .info-close-button-container img {
    width: 35px;
    height: 35px;
  }

  .information-svg {
    margin-top: 15vh;
    width: 60%;
  }

  ul {
  padding-left: 20px;
  font-family: "Shippori Mincho", serif;
  font-size: 0.9rem;
}

li {
  margin-bottom: 10px;
  line-height: 1.6;
}

}
</style>
